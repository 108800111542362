<template>
  <div>
    <div style="margin-bottom: 20px;margin-left:20px;margin-top: 30px;">
      <el-row>
        <el-col :span="24" align="left">
          <el-button type="primary" @click="goBack" size="small">返回</el-button>
        </el-col>
      </el-row>
    </div>
    <el-form ref="perForm" :model="bmsCompanyInfoDto" :rules="rules" label-width="200px" style="margin-bottom:60px">
      <el-form-item label="	企业logo" prop="companyLogo">
        <el-upload class="avatar-uploader" action="http://up.qiniup.com" :show-file-list="false"
          accept="image/jpeg,image/gif,image/png" :on-success="handleAvatarSuccess" :data="postData"
          :before-upload="beforeAvatarUpload">
          <div v-if="imageUrl" class="avatar">
            <img :src="imageUrl" width="100%" />
          </div>
          <!-- <img v-if="imageUrl" :src="imageUrl" class="avatar"> -->
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>

        </el-upload>
      </el-form-item>

      <el-form-item label="企业名称" prop="companyName">
        <el-input v-model="bmsCompanyInfoDto.companyName" placeholder="请输入企业名称" style="width: 50%" />
      </el-form-item>

      <el-form-item label="企业地区" prop="areaId">
        <el-select v-model="bmsCompanyInfoDto.areaId" placeholder="请选择" @change="selectChange">
          <el-option v-for="(item, index) in options" :key="index" :label="item.areaName" :value="item.areaId">
          </el-option>
        </el-select>
      </el-form-item>


      <el-form-item label="企业地址" prop="address">
        <el-input v-model="bmsCompanyInfoDto.address" placeholder="请输入企业地址" style="width: 50%" />
      </el-form-item>
      <el-form-item label="管理员姓名" prop="username">
        <el-input v-model="bmsCompanyInfoDto.username" placeholder="请输入管理员姓名" style="width: 50%" />
      </el-form-item>
      <el-form-item label="管理员账号" prop="account">
        <el-input v-model="bmsCompanyInfoDto.account" placeholder="请输入管理员账号" style="width: 50%" />
      </el-form-item>

      <el-form-item label="联系电话" prop="tel">
        <el-input v-model="bmsCompanyInfoDto.tel" placeholder="请输入联系电话" style="width: 50%" />
      </el-form-item>













    </el-form>
    <el-row slot="footer" type="flex" justify="center">
      <el-col :span="6">
        <el-button size="small" type="primary" @click="btnOK">
          确定
        </el-button>
        <el-button size="small" @click="btnCancel">取消</el-button>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import {


  getUploadToken,
  getallarealist,
  addcompany,
  updatecompany,
} from "../../request/http";
export default {
  data() {
    return {

      editOradd: "",
      imageUrl: "",
      postData: {
        token: "",
        key: "", //上传的Logo
      },

      options: [],
      bmsCompanyInfoDto: {
        companyLogo: "",
        areaId: "",
        companyName: "",
        account: "",
        tel: "",
        username: "",
        address: '',
      },
      rules: {


        companyName: [
          { required: true, message: "企业名称不能为空", trigger: "blur" },
        ],

        account: [{ required: true, message: "管理员账号不能为空", trigger: "blur" },
        {
          pattern:
            /^(13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[89])\d{8}$/,
          message: "请输入正确的手机号码",
        },],

        username: [
          { required: true, message: "管理员姓名不能为空", trigger: "blur" },
        ],

        areaId: [
          { required: true, message: "企业所在地区不能为空", trigger: "blur" },
        ],

      },


    };


  },
  created() {
    this.getList()

    console.log(this.$route.query);
    //editOradd 传过来的为 0 或者 1
    this.editOradd = this.$route.query.editOradd == 0 ? false : true;
    if (this.$route.query.obj) {
      let val = this.$route.query.obj;
      this.imageUrl = "http://farmfile.uutele.com/" + val.companyLogo;
      this.bmsCompanyInfoDto = {
        companyId: val.companyId,
        companyLogo: val.companyLogo,
        areaId: val.areaId,
        companyName: val.companyName,
        tel: val.tel,
        username: val.username,
        account: val.account,
        address: val.address,


      }
    }
  },
  mounted() { },

  methods: {
  

   
  
  
  
    async getList() {
      let {data} = await getallarealist();
      console.log(data);

      this.options = data.data;
    },
    selectChange(value) {
      this.bmsCompanyInfoDto.areaId = value;
      console.log(this.bmsCompanyInfoDto.areaId);
    },

    // 返回上一级页面
    goBack() {
      this.$router.go(-1);
    },
    // 确认
    async btnOK() {
      this.$refs.perForm.validate().then(async () => {

        if (this.bmsCompanyInfoDto.companyId) {

          let { data } = await updatecompany(this.bmsCompanyInfoDto);
          if (this.bmsCompanyInfoDto.companyId && data.code == 0) {
            this.$message.success("编辑成功");
          } else {
            console.log(data.msg);
            this.$message({
              type: "error",
              message: data.msg,
            });
          }
          this.goBack();
        } else {

          let { data} = await addcompany(this.bmsCompanyInfoDto);
        
          if (data.code == 0) {
            this.$message.success("新增成功");
          } else {
            console.log(data.msg);
            this.$message({
              type: "error",
              message: data.msg,
            });
          }
          this.goBack();
        }
      });

    },

    //弹窗取消函数
    btnCancel() {
      this.goBack();
    },


    // 上传图片方法
    handleAvatarSuccess(res, file) {
      console.log("上传成功", res, file);
      this.imageUrl = "http://farmfile.uutele.com/" + res.key;
      this.bmsCompanyInfoDto.companyLogo = res.key;
    },
    async beforeAvatarUpload(file) {
      // console.log("上传前");
      let { data } = await getUploadToken();
      // console.log(data);
      if (data.data == "" || data.data == null) {
        this.message.error("上传图片时获取Token失败！");
        return;
      }
     
      this.postData.token = data.data.token;
      this.postData.key = data.data.fid;
    },

  },
};

</script>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>